<template>
  <div class="broadcast-html check-event">
    <div class="content-initial">
      <div class="cabecalho">
        <BaseHeader title="Eventos do e-mail" :navigation="navigation"> </BaseHeader>
        <div class="box-total" style="justify-content: center;">
          <p class="total-vendas">
            Total de e-mails {{ totalText }}:
            <span>{{ total }}</span>
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="espacemento-select">
        <svg
          class="svg-filter"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 0.759033H1L7 8.64237V14.0924L10 15.759V8.64237L16 0.759033Z"
            stroke="#4ea934"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <multiselect
          v-model="new_event_selected"
          label="text"
          track-by="value"
          placeholder="Selecionar evento"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          open-direction="bottom"
          :options="options_event"
          :multiple="false"
          :searchable="false"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :show-no-results="true"
          :hide-selected="false"
          @input="trocaEvento2"
        >
        </multiselect>
      </div>
      <div class="leads-cards" v-if="loading">
        <div class="lead-card loading-card" v-for="n in 15">
        </div>
      </div>
      <div class="leads-cards" v-else-if="broadcasts.length">
        <div class="lead-card" @click="openLead(event.lead_id)" v-for="event in broadcasts" :key="event.id">
          <div class="lead-data">
            <div
              class="lead-picture"
            >
              <div
                class="lead-initial"
              >
                {{ event.lead && event.lead.name ? getMetaInitialLead(event.lead.name) : '??' }}
              </div>
            </div>
            <div class="lead-texts">
              <p class="lead-name">{{ event.lead && event.lead.name ? event.lead.name : 'Lead desconhecido' }}</p>
              <p style="
                  overflow: hidden;
                  max-width: 100%;
                  text-overflow: ellipsis;
                "
              >
                {{ event.address }}
              </p>
              <p class="lead-score">
                {{ returnFormatedDate(event) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="nao-encontrado" style="text-align: center;" v-else>
        Nenhum item correspondente
      </div>

      <b-row>
        <b-col>
          <Paginate
            v-if="total > 0"
            :totalPages="totalPage"
            :activePage="currentPage"
            @to-page="toPage"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Paginate from "@/components/Paginate";
import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();

import Multiselect from "vue-multiselect";

import moment from 'moment-timezone';

export default {
  name: "listagem",
  components: {
    Multiselect,
    Paginate
  },
  data() {
    return {
      loading: true,
      navigation: [
        { link: "Funis", to: "/dynamicRoute/funis/lista" },
        { link: "E-mail do funil", to: this.$route.fullPath },
      ],
      new_event_selected: { value: "delivered", text: "Entregues" },
      total: "00",
      totalText: '',
      broadcasts: [],
      totalPage: 1,
      currentPage: 1,
      event_selected: "delivered",
      event_selected_text: "Entregues",
      options_event: [
        { value: "delivered", text: "Entregues" },
        { value: "open", text: "Abertos" },
        { value: "click", text: "Clicados" },
        { value: "spam", text: "Spams" },
        { value: "unsubscribed", text: "Unsubscribeds" },
        { value: "bounce", text: "Bounces" },
        { value: "dropped", text: "Droppeds" },
        { value: "deferred", text: "Deferreds" },
      ],
      id_selected: "",
    };
  },
  mounted() {
    this.getEmail();
  },
  methods: {
    returnFormatedDate(event){
      if(event.updated_at){
        return moment.tz(event.updated_at, "America/Sao_Paulo").format('DD/MM/YYYY HH:mm');
      }
      return moment.tz(event.sg_timestamp, "America/Sao_Paulo").subtract(3, 'hours').format('DD/MM/YYYY HH:mm');
    },
    trocaEvento2(event) {
      this.event_selected = event.value;
      this.event_selected_text = event.text;
      this.getList();
    },
    toPage(page) {
      this.currentPage = page;
      this.getList();
    },
    openLead(id) {
      const url = `${process.env.BASE_URL}${this.$store.getters.user.user.tenant_subdomain}/lead/${id}`;
      window.open(url, "_blank");
    },
    getMetaInitialLead(user) {
      if (user) {
        var name = user.split(" ")[0];
        var sobrenome = user.split(" ")[1] ?? undefined;
        if (!name && !sobrenome) {
          return "";
        }
        if (!sobrenome) {
          return name.substring(0, 2);
        }
        return name[0] + sobrenome[0];
      }
      return;
    },
    getEmail() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.id_selected = urlParams.get("email");
      this.getList();
    },
    switchTextTotal() {
      switch (this.event_selected) {
        case "delivered":
          this.totalText = "entregues";
          break;
        case "open":
          this.totalText = "abertos";
          break;
        case "click":
          this.totalText = "clicados";
          break;
        case "spam":
          this.totalText = "considerados spam";
          break;
        case "unsubscribed":
          this.totalText = "desinscritos";
          break;
        case "bounce":
          this.totalText = "bounce";
          break;
        case "dropped":
          this.totalText = "dropped";
          break;
        case "deferred":
          this.totalText = "deferred";
          break;
      }
    },
    lastPage() {
      if (this.currentPage === 1) {
      } else {
        this.currentPage = this.currentPage - 1;
        this.getList();
      }
    },
    nextPage() {
      if (this.currentPage === this.totalPage) {
      } else {
        this.currentPage = this.currentPage + 1;
        this.getList();
      }
    },
    getList() {
      this.loading = true
      serviceEmail
        .read(
          this.id_selected +
            `/event/` +
            this.event_selected +
            `?page=` +
            this.currentPage + 
            '&type=funnel'
        )
        .then((resp) => {
          this.broadcasts = resp.data;
          this.totalPage = resp.last_page;
          this.total = resp.total;
          this.switchTextTotal();
          this.loading = false
        })
        .catch((err) => { this.loading = false
        });
    },
  },
};
</script>

<style scoped lang="scss">
.lead-data {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;

  .lead-picture {
    aspect-ratio: 1;
    border-radius: 100%;
    overflow: hidden;
    width: 5vw;
    min-width: 60px;
    width: 60px;
    min-height: 60px;
    height: 60px;
    background-size: cover;
    background-position-x: center;
    background-color: var(--greenn2);
    border: 2px solid var(--greenn);

    * {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .lead-initial {
      font-size: 27px;
      color: var(--greenn);
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
    }

  }

  .lead-texts {
    width: -webkit-fill-available;
    width: -moz-available;
    max-width: calc(100% - 73px);
  }

  .lead-score {
    font-size: 12px !important;
    font-weight: 400;
    color: var(--greenn) !important;
  }

  .lead-name {
    font-size: 16px !important;
    font-weight: 600;
    color: #141414;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 25px;
    word-break: break-all;
    white-space: nowrap;
  }
}

.leads-cards {
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 14px));
  gap: 21px;
  p {
    margin-bottom: 0 !important;
  }
  .lead-card {
    border: 1px solid var(--white-medium);
    border-radius: 10px;
    padding: 15px;
    transition: .3s;
    &:hover {
      background-color: var(--greenn2);
      border-color: var(--greenn);
      cursor: pointer;
    }
  }
}
.broadcast-html {
  .espacemento-select {
    width: 30%;
    position: relative;
    display: flex;
    margin-bottom: 30px;
  }

  svg.svg-filter {
    position: absolute;
    z-index: 100;
    top: 18px;
    left: 18px;
  }

  .content-initial {
    display: flex;
  }

  .cabecalho {
    margin-bottom: 15px;
    width: 100%;
  }

  input#input-pagination {
    width: 64px;
    height: 50px;
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #000;
    display: flex;
    text-align: -webkit-center;
  }
}
.loading-card {
  height: 92px;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shine 1.5s linear infinite;
  border-color: var(--white-medium) !important;
  cursor: wait;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-moz-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@-o-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

</style>

<style>
.espacemento-select .multiselect__tags {
  padding-left: 50px !important;
}
.broadcast-html .wrap-pages{
  display: none !important;
}
</style>