<template>
    <div class="container">
        <listagem />
    </div>
</template>

<script>
import Listagem from '@/components/Campanhas/ListagemEvento.vue';

export default {
    components: {
        Listagem,
    }
}
</script>